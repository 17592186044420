$(document).ready(() => {
	$('#hero-slider').sliderPro({
		slideAnimationDuration: 2000,
		autoplayDelay: 7000,
		waitForLayers: true,
		touchSwipe: false,
		fadeOutPreviousSlide: true,
		autoplay: true,
		loop: false,
		width: '100%',
		height: ' 400px',
		autoSlideSize: false,
		arrows: true,
		fadeArrows: false,
		responsive: true,
		buttons: false,
	});
});

